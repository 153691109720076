import React from 'react'
import Navbar from '../components/Navbar'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Courses from '../pages/Courses'
import Noorani from '../pages/Noorani'
import Recitation from '../pages/Recitation'
import Memorization from '../pages/Memorization'
import Tajweed from '../pages/Tajweed'
import Kalma from '../pages/Kalma'
import Naat from '../pages/Naat'
import About from '../pages/About'
import Fee from '../pages/Fee'
import Download from '../pages/Download'
import Contact from '../pages/Contact'
import Footer from './Footer'

const Routing = () => {
  return (
   <>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/courses' element={<Courses />} />
          <Route path='/noorani' element={<Noorani />} />
          <Route path='/recitation' element={<Recitation />} />
          <Route path='/memorization' element={<Memorization />} />
          <Route path='/tajweed' element={<Tajweed />} />
          <Route path='/kalma' element={<Kalma />} />
          <Route path='/naat' element={<Naat />} />
          <Route path='/about' element={<About />} />
          <Route path='/fee' element={<Fee />} />
          <Route path='/download' element={<Download />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>

  <Footer/>
   </>
  )
}

export default Routing