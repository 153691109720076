import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import Sidebar from '../components/Sidebar';


const Noorani = () => {
  return (
    <>
      <div className="noorani">
          <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <div className="top mb-4 border">
                      <h3 className='yellow-color pb-3 fw-bold bg-dark p-3'>Noorani Qaida Course, Learn Quranic Arabic online</h3>
                            <div className="main-content p-3">
                            <div className="img-wrapper">
                            <img src="images/noorani.jpg" alt="sample" className='img-fluid' />
                            </div>
                            <p className='my-4'>Are you interested in learning the Quran but haven't yet familiarized yourself with the Arabic alphabet? This course is tailor-made for you. Embark on your journey to learn Quranic reading with skilled Arabic instructors, regardless of your starting point.</p>

                            </div>

                            <div className="middle border p-3">
                            <h4>Finding it challenging to take your kids to the mosque?</h4>
                            <hr />
                            <p>Learning can take place online through platforms like <strong className='main-color'>WhatApp</strong> , <strong className='main-color'>Skype</strong> . Enrolling in a <strong>Noorani Qaida course</strong> can be beneficial for mastering the pronunciation of Arabic alphabets. As learners progress, they can gradually advance to forming words by combining these letters.</p>
                            <p><AiOutlineArrowRight className='main-color' /> Arabic Alphabet & their Proper Pronunciation.</p>
                            <p><AiOutlineArrowRight className='main-color'/> Letter Recognition, Positions and Connecting Letters.</p>
                            <p><AiOutlineArrowRight className='main-color'/> Memorizing some selected Surahs chosen.</p>
                            <p><AiOutlineArrowRight className='main-color'/> Short Vowels (Harakat).</p>
                            <p><AiOutlineArrowRight className='main-color'/> Long Vowels (Huroof Maddah).</p>
                            <p><AiOutlineArrowRight className='main-color'/> Sukoon, Tanween, Letters of Leen.</p>
                            <p><AiOutlineArrowRight className='main-color'/> Rules of Noon Sakinah & Tanween.</p>
                            <p>By the end of this course they will be able to look directly into the Quran and read it well In Sha Allah.</p>
                            


                            <h4 className='mt-5'>Read More About the <strong className='main-color text-decoration-underline'>Noorani Qaida Course</strong></h4>
                            <hr />

                            <p> <strong>What is Noorani Qaida?</strong></p>
                            <p>The first thing to do when learning to read the Quran is to get to know and be able to read its letters. The Noorani Qaidah is the basic book to learn these Arabic letters. Before you can recite the Holy Quran correctly, you must learn from the Noorani Qaidah. Once you're good at this, reading the Quran becomes easier. You can think of it as the starting point for learning how to read the Holy Quran.</p>
                            
                            <p> <strong>Why learn Noorani Qaidah?</strong></p>
                            <p>Noorani Qaidah serves as a fundamental guide for understanding the principles of proper pronunciation when reciting the Holy Quran. Learning Noorani Qaidah prior to delving into Quranic recitation enhances one's fluency and proficiency in delivering the verses.

                            Given the intricacies of the Arabic language, especially for individuals from diverse linguistic backgrounds, a crucial preliminary step before engaging with the Holy Quran is to acquaint oneself with the fundamental principles of Arabic pronunciation. The most effective approach to achieve this is through the study of Noorani Qaidah.</p>
                            
                            </div>
                        </div>
                  </div>
                  <div className="col-lg-4">
                  
                  <div className="card p-3 shadow mb-3">
                  <h5 className='fw-bold yellow-color bg-dark p-3 mb-4'>Course Outlines:</h5>
                      <ol>
                        <li>Arabic Alphabet & their Proper Pronunciation</li>
                        <li>Letter Recognition</li>
                        <li>Letter Positions</li>
                        <li>Connecting Letters</li>
                        <li>Short Vowels (Harakat)</li>
                        <li>Long Vowels (Huroof Maddah)</li>
                        <li>Sukoon</li>
                        <li>Tanween</li>
                        <li>Letters of Leen</li>
                        <li>Rules of Noon Sakinah & Tanween</li>
                        <li>Rules of Raa</li>
                        <li>Rules of Laam</li>
                        <li>Noon Qutni</li>
                        <li>Waqf ( Proper Pausing and Stopping).</li>
                      </ol>
                  </div>

                      <div className="card shadow mb-3 p-3">
                          <h5 className='fw-bold yellow-color bg-dark p-3'>Course Structure:</h5>
                          <div className="side-content p-3">
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Course Name:</span> Noorani Qaida Course Online</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Class type:</span> One to One</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Age:</span> 6 to 60+</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>For:</span> Beginners (Kids & Adults)</p>
                          </div>
                      </div>

                      <div className="card p-3 shadow mb-3">
                          <Sidebar />
                      </div>

                  </div>
              </div>
          </div>
      </div>
    </>
  )
}

export default Noorani