import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube, FaBuilding, FaPhone, FaEnvelope, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
    <hr />
      <div className="img-wrapper text-center mt-4">
        <img src="images/footer.png" alt="footer" className='img-fluid' />
      </div>
      <div className="footer main-bg">
        <div className="container">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-3 mt-lg-5 text-center text-lg-start">
              <h5 className="text-light  fw-bold mt-3">Nurjan Quran Academy</h5>
              <hr className="bg-light mb-4" />
              <p className="text-light pe-lg-3 mt-3 lh-3 me-lg-3">
              Enriching Quranic learning at Nurjan Academy: Noorani Qaida, Quran Recitation, Hifz, Tajweed, Kalma & Dua courses. Expert guidance for spiritual growth and Quranic proficiency. Join us for a transformative journey.
              </p>
            </div>

            <div className="col-lg-3 mt-lg-5 text-center">
              <h5 className="text-light text-uppercase fw-bold mt-3">Other Page</h5>
              <hr className="bg-light mb-4" />
              <p>
                <Link to="/" className="text-light text-decoration-none" onClick={scrollToTop}>
                  Home
                </Link>
              </p>
              <p>
                <Link className="text-light text-decoration-none" to="/fee" onClick={scrollToTop}>
                  Fee Structure
                </Link>
              </p>
              <p>
                <Link className="text-light text-decoration-none " to="/about" onClick={scrollToTop}>
                  About
                </Link>
              </p>
              <p>
                <Link className="text-light text-decoration-none" to="/contact" onClick={scrollToTop}>
                  Contact
                </Link>
              </p>
            </div>

            <div className="col-lg-3 text-center text-lg-start mt-lg-5">
            <h5 className="text-light text-uppercase text-center fw-bold mt-3 ">Quick Contact</h5>
            <hr className="bg-light mb-4" />

            <p className="text-light">
              <FaBuilding className='me-3 ' />5B Shershah Block Commercial Area Garden Town,54000 Punjab Lahore
            </p>
            <p className="text-light">
            <FaPhone className='me-3 ' /><a href="tel:+923374696146" className="text-decoration-none text-light">+92-340-6239634</a>
            </p>
            <p className="text-light">
              <FaEnvelope className="me-3"/><a href="mailto:nurjanquranacademy@gmail.com" className="text-decoration-none text-light">admission@nurjanquranacademy.com</a>
            </p>

            <div className=" mt-4 text-center text-lg-start">
            <a href="https://www.facebook.com/profile.php?id=100095114482885" target='_blank' rel='noreferrer'><FaFacebook className='text-light me-3 h3'/></a>
            <a href="https://www.instagram.com/nurjanquranacademy/?utm_source=qr&igshid=NGExMmI2YTkyZg%3D%3D" target='_blank' rel='noreferrer'><FaInstagram className='text-light me-3 h3'/></a>
            <a href="https://www.youtube.com" target='_blank' rel='noreferrer'><FaYoutube className=' text-light me-3 h3' /></a>
            <a href="https://www.linkedin.com/company/96659112/" target='_blank' rel='noreferrer'><FaLinkedin className=' text-light me-3 h3' /></a>
           
          </div>
          </div>

          <div className="col-lg-3 text-center text-lg-start mt-lg-5">
          <h5 className="text-light text-uppercase fw-bold mt-3 text-center ">Payment methods</h5>
          <hr className="bg-light mb-2" />

            <div className="img-wrapper text-center mt-lg-4">
                  <img src="images/payments-nurjan.png" alt="payment" className='img-fluid' width={200} />
            </div>
          <h5 className=' text-center mt-3'><span>Remitly | Wise | Xoom</span></h5>

            </div>
          </div>
        </div>
      </div>
      <h6 className='text-center text-light mb-0 pb-3 main-bg pt-4'>© Copyright Protected | NurjanQuranAcademy 2023. Developed by
       <a href="https://stelogix.com/" target="_blank" className='text-dark mx-1'> Stelogix Technologies</a></h6>
    </>
  );
};

export default Footer;
