import React from 'react';
import { FaCalendarAlt, FaLanguage, FaUserFriends, FaDollarSign,FaBook} from 'react-icons/fa';
import { GiTeacher } from 'react-icons/gi';
import { BiSolidReport } from 'react-icons/bi';
import { PiCertificate } from 'react-icons/pi';
import Form from '../components/Form';
import { Link } from 'react-router-dom';


const Home = () => {



  const handleNavLinkClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };


  return (
   <>
      <div className="slider pt-5">
      <div className="container">
        <div className="row d-flex justify-content-between">
        <div className="col-lg-4 text-center text-lg-start mt-lg-4">
            <div className="img-wrapper ">
                  <img src="images/homeicon.png" alt="homeicon" className='img-fluid IconImage' />
            </div>
        </div>
          <div className="col-lg-8 ">
              <div className="img-wrapper mt-lg-5 text-center text-lg-start">
                <img src="images/bismillah.png" alt="ayat" width={150} className='img-fluid'/>
              </div>
              <div className="img-wrapper ms-4 text-center text-lg-start">
              <img src="images/whiteunderline-nurjan.png" alt="underline" className='img-fluid ' width={100} />
            </div>
            <h2 className='text-light my-4  text-center text-lg-start '>Online Quran Learning with <span className='text-dark h1 fw-bold'>Nurjan Quran Academy</span></h2>
            <p className='text-white text-center text-lg-start'>Explore our Quran Learning courses, featuring online classes for kids and adults. Benefit from our skilled Quran teachers, who conduct personalized one-on-one sessions. These sessions cater to diverse age groups, educational levels, and time availabilities, ensuring a flexible and effective learning experience.</p>
            <h3 className='text-dark text-center text-lg-start'>Follow 3 steps to start your classes</h3>

                <div className="steps text-center text-lg-start">
                      <div className="row">
                        <div className="col-lg-4 ">
                          <h5 className='text-light  my-lg-3 p-2'><span className='bg-light px-2 rounded-circle  me-3 text-dark fw-bold'>1</span>Text or Call on WhatsApp </h5>
                        </div>
                        <div className="col-lg-4">
                        <h5 className='text-light  my-lg-3   p-2'><span className='bg-light px-2 rounded-circle me-3 text-dark fw-bold'>2</span>Student Assessment</h5>
                        </div>

                        <div className="col-lg-4">
                        <h5 className='text-light  my-lg-3   p-2'><span className='bg-light px-2 rounded-circle me-3 text-dark fw-bold'>3</span>Start your class</h5>
                        </div>
                      </div>
                </div>
                <div className="text-center text-lg-start">
                <Link className='btn btn-lg yellow-bg text-dark bg-light shadow mt-4 fw-bold free-trail' onClick={handleNavLinkClick} to="/contact" >Start <span className='fw-bold yellow-color text-uppercase'>3 days</span> Demo Classes </Link> 
                </div>

            </div>

        </div>
      </div>
    </div>

    <div className="step py-5 my-5">

        <div className="img-wrapper text-center">
          <img src="images/nurjan.png" alt="ayat" width={300} className='img-fluid'/>
        </div>
        

        <div className="container mt-4">
              <div className="row">
                  <h1 className='text-center main-color fw-bold'>Online Quran Courses</h1>

                  <div className="img-wrapper text-center">
                  <img src="images/underline-nurjan.png" alt="underline" className='img-fluid ' width={200} />
                </div>
                  <p className='text-center px-lg-5 py-4'>Our goal is to offer accessible Online Quran classes to people from diverse backgrounds. Whether you're a novice or seeking to enrich your current understanding, our online Quran learning courses are expertly designed to cater to your requirements.We're dedicated to providing convenient Online Quran classes worldwide, including Canada, USA, UK, Australia, Europe, and the UAE. Our online Quran learning courses cater to individuals from all corners of the globe.</p>

                   <div className="col-lg-3 my-3">
                      <Link onClick={handleNavLinkClick} to="/noorani" className='text-decoration-none'>
                      <div className="card course-card  px-3 py-3 rounded-5 ">
                      <div className="img-wrapper text-center">
                          <img src="images/noorani.jpg" alt="noorani-qaida-pic" width={300}  className='img-fluid'/>
                           
                          </div>
                </div>
                      </Link>
                   </div>
                   <div className="col-lg-3 my-3">
                      <Link onClick={handleNavLinkClick} to="/recitation">
                      <div className="card course-card  px-3 py-3 rounded-5" >
                      <div className="img-wrapper text-center">
                          <img src="images/recite.jpg" alt="recite-quran-pic" width={300}  className='img-fluid'/>
                          </div>
                      </div>
                      </Link>
                   </div>
                   <div className="col-lg-3 my-3">
                      <Link onClick={handleNavLinkClick} to="/memorization">
                      <div className="card course-card  px-3 py-3 rounded-5">
                      <div className="img-wrapper text-center">
                          <img src="images/memorize.jpg" alt="quran-memorization-pic" width={300}  className='img-fluid'/>
                          
                          </div>
                        </div>
                      </Link>
                   </div>
                   <div className="col-lg-3 my-3">
                      <Link onClick={handleNavLinkClick} to="/tajweed">
                      <div className="card course-card  px-3 py-3 rounded-5">
                      <div className="img-wrapper text-center">
                          <img src="images/tajweed.jpg" alt="tajweed-course-pic" width={300}  className='img-fluid'/>
                        
                          </div>
                          </div>
                      </Link>
                   </div>

              </div>
              <div className="row d-flex align-items-center justify-content-center">
                   <div className="col-lg-3 my-3">
                      <Link onClick={handleNavLinkClick} to="/kalma">
                      <div className="card course-card   px-3 py-3 rounded-5">
                      <div className="img-wrapper text-center">
                          <img src="images/kalma.jpg" alt="inquiry" width={300}  className='img-fluid'/>
                          </div>
                        </div>
                      </Link>
                   </div>
                   <div className="col-lg-3 my-3">
                      <Link onClick={handleNavLinkClick} to="/naat">
                      <div className="card course-card  px-3 py-3 rounded-5">
                      <div className="img-wrapper text-center">
                          <img src="images/naat.jpg" alt="inquiry" width={300}  className='img-fluid'/>
                          </div>
                          </div>
                      </Link>
                   </div>
                   

              </div>
        </div>
    </div>

    <div className="skypelearning my-5 mb-5 px-3 px-lg-0 text-center text-lg-start ">
    <div className="img-wrapper text-center my-3">
          <img src="images/tarteel.png" alt="ayat" width={150} className='img-fluid'/>
        </div>
        
    <div className="container">
    <h1 className='text-center fw-bold main-color'>ONLINE QURAN CLASSES</h1>

    <div className="img-wrapper text-center my-3">
    <img src="images/underline-nurjan.png" alt="underline" className='img-fluid ' width={200} />
  </div>

  <h6 className='mt-3 fw-bold'>Why is there need to learn Quran for kids?</h6>
  <p>In countries outside the Muslim world, finding a suitable Quran tutor can be challenging, especially in places like the USA and UK. This scarcity led us to create a solution – Nurjan Quran Academy. Our online Quran classes cater to both kids and adults, offering equal opportunities. Our proficient teachers, hailing from diverse countries, can instruct your children in various languages, eliminating language barriers. Nurjan Quran Academy addresses the dearth of Quran tutors by providing effective and accessible online Quran learning.</p>
  
  <h6 className='mt-3 fw-bold'>BENEFITS OF LEARNING FROM US</h6>
  <p>Experience effective technological integration in education with our Quran Academy. Join our Learn Quran Arabic Classes, where highly qualified teachers facilitate interactive Quran learning. Our instructors are proficient in English, Arabic, and Urdu, enabling seamless communication. They are also Hafiz-e-Quran, adeptly reciting with an Arabic accent. Embrace a modern approach to Quranic education that bridges teacher-student interactions.
  </p>
  <h6 className='mt-3 fw-bold'>HOME BASED LEARNING OPPURTUNITY</h6>
  <p>Join our online academy for Quran learning and teaching from home. Enjoy the comfort of studying the Quran at your own place. Our Islamic Academy offers various courses without age limits. You can learn the Quran without traveling. Nurjan Quran Academy has different courses for you to choose from. Skilled teachers will help you improve your Quran recitation. Trusted reciters are here to assist Muslims worldwide. If you want to learn the Quran, this is the best place to start. Nurjan Quran Academy will guide you in selecting and beginning your desired course for a better Quran education.</p>
  
  
  <h6 className='mt-3 fw-bold'>SATISFACTION OF CLIENTS</h6>
  <p>Our top priority is making sure our clients are happy. If you're not satisfied with your Tutor, you can talk to our customer service. They're available 24/7 and ready to solve any problems you have. Adults and kids can now learn the Quran online, word by word, right from home. Our Quran teachers from the Online Quran Academy are here to help. They're making it easy to learn the Holy Quran step by step, all from the comfort of your home. No more confusing materials, just simple learning.</p>
    </div>
    
</div>

    <div className="banner px-lg-5 py-5 mt-5 ">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-around">
              <div className="col-lg-5">
                  <div className="img-wrapper text-center text-lg-center mb-3">
                      <img src="images/Quran.webp" alt="quran" className='img-fluid' width={300}/>
                  </div>
                  
                  <p className='text-light h5 text-center mt-5'>Avail our Complimentary Trial Classes by Completing the Form to Learn Quran Online. Experience the Three-Day Free Trial Offer from <span className='text-dark fw-bold'>Nurjan Quran Academy</span></p>
              </div>

              <div className="col-lg-4 text-lg-start text-center">
               
               <div className="card py-4 px-4 banner-card">
                <h3 className='text-center text-light text-uppercase'>3 Days Free Classes</h3>
                <div className="img-wrapper text-center mb-3">
                <img src="images/whiteunderline-nurjan.png" alt="underline" className='img-fluid ' width={200} />
              </div>
               <Form />
               </div> 

              </div>
          </div>
        </div>
    </div>

    <div className="whywe py-5 my-lg-5">

    <div className="img-wrapper text-center mt-4">
          <img src="images/rabisharahli-nurjan.png" alt="ayat" width={200} className='img-fluid'/>
        </div>
    <div className="container">
    <h1 className='text-center main-color fw-bold mt-3'>Why Nurjan Quran Academy</h1>

    <div className="img-wrapper text-center my-3">
    <img src="images/underline-nurjan.png" alt="underline" className='img-fluid ' width={200} />
  </div>

        <p className='text-center my-3 px-3 px-lg-5 '>We teach Quran in a way that's easy and faster than usual. People from anywhere in the world can join us from home. We offer different ways to learn how to read the Quran correctly. We also have Tajweed classes to help you get better at Quranic skills.</p>
        <div className="row">
          <div className="col-lg-3 text-center py-3">
              <div className="card p-4 shadow d-flex justify-content-center align-items-center">
                <FaCalendarAlt className='yellow-color ' size={50} />
                <h5 className='mt-4 fw-bold main-color '>Flexible Schedule 24/7</h5>
                <p>Our team of experts is available 24/7 to help you and your children learn the Quran online at your convenience. No matter where you are, you can easily join our classes and enjoy this wonderful opportunity for learning, day or night.</p>
              </div>
          </div>

          <div className="col-lg-3 text-center py-3">
              <div className="card p-4 shadow d-flex justify-content-center align-items-center">
                <GiTeacher className='yellow-color ' size={50}/>

              <h5 className='mt-4 fw-bold main-color'>Qualified Quran Teachers</h5>
              <p>At Nurjan Quran Academy, we carefully choose our teachers who are skilled and well-trained. Our team includes men and women who are experts in teaching the Quran online. They work hard to give great Quran lessons to kids and grown-ups.</p>

              </div>
          </div>
          
          <div className="col-lg-3 text-center py-3">
              <div className="card p-4 shadow  d-flex justify-content-center align-items-center">
                <FaLanguage className='yellow-color ' size={50}/>
                <h5 className='mt-4 fw-bold main-color text-capitalized'>Multilingual Classes Available</h5>
                <p>Learn Quran online with our multi-language classes in English and Urdu. Join our online Quran academy for interactive lessons, expert tutors, and flexible schedules. Discover the convenience of online Quran learning today.</p>

              </div>
          </div>


          <div className="col-lg-3 text-center py-3">
          <div className="card p-4 shadow  d-flex justify-content-center align-items-center">
          <FaUserFriends className='yellow-color ' size={50}/>
          <h5 className='mt-4 fw-bold main-color'>1 to 1  Sessions</h5>
          <p>Discover the Online Quran Academy's interactive courses featuring personalized, live, one-on-one video sessions. Learn Quranic teachings conveniently through online Quran learning, guided by expert instructors. Start your journey today!</p>

          </div>
          </div>

          
        </div>
        <div className="row">
          <div className="col-lg-3 text-center py-3">
              <div className="card p-4 shadow d-flex justify-content-center align-items-center">
                <BiSolidReport className='yellow-color' size={50} />
                <h5 className='mt-4 fw-bold main-color'>Weekly performance reports</h5>
                <p>Monitor your child's progress with ease through our online Quran academy. Receive daily, weekly and monthly performance reports, ensuring a seamless and effective online Quran learning experience for your kids.</p>
              </div>
          </div>

          <div className="col-lg-3 text-center py-3">
              <div className="card p-4 shadow d-flex justify-content-center align-items-center">
                <FaDollarSign className='yellow-color' size={50}/>
                <h5 className='mt-4 fw-bold main-color'>Easy Payments</h5>
                <p>When sending money through <a href="https://www.paypal.com" rel='noreferrer' >PayPal</a>, <a href="https://www.remitly.com/us/en" target='_blank' rel='noreferrer'>Remitly</a>, <a href="https://wise.com/" target='_black' rel='noreferrer'>Wise</a>, there is a fee for the transaction. This fee varies based on factors like the amount sent and the destination. Be sure to check the fee details before sending money.</p>
              </div>
          </div>
          
          <div className="col-lg-3 text-center py-3">
              <div className="card p-4 shadow d-flex justify-content-center align-items-center">
                <FaBook className='yellow-color' size={50}/>
                <h5 className='mt-4 fw-bold main-color text-capitalized'>Quality Material</h5>
                <p>Join our online Quran academy for top-quality materials. Experience interactive learning with E-learning tools, worksheets, and a supportive environment. Start your journey towards understanding the Quran today!</p>

              </div>
          </div>


          <div className="col-lg-3 text-center py-3">
              <div className="card p-4 shadow d-flex justify-content-center align-items-center">
                <PiCertificate className='yellow-color' size={50} />
                <h5 className='mt-4 fw-bold main-color'>Course Completion Certificate</h5>
                <p>Nurjan Quran Academy gives students a certificate of achievement when they complete their course successfully. This certificate shows they are skilled and finished the program with confidence.</p>

              </div>
          </div>

          
        </div>


    </div>
</div>

   </>
  );
}

export default Home;
