import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import Sidebar from '../components/Sidebar';

const Tajweed = () => {
  return (
    <>
    <div className="noorani">
    <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <div className="top mb-4 border">
                      <h3 className='yellow-color pb-3 fw-bold bg-dark p-3'>Best Online Quran Memorization Course</h3>
                            <div className="main-content p-3">
                            <div className="img-wrapper">
                            <img src="images/tajweed.jpg" alt="sample" className='img-fluid' />
                            </div>
                            <p className='my-4'>Tajweed, an Arabic term, carries the essence of enhancing and refining. It originates from the root word "jayyid," signifying excellence or goodness.
                            <br /><strong>Allah says:</strong> “Those to whom We gave the Book recite it as it should be recited, they are the ones that believe therein.” (2:121)
                            <br /><strong>In Sunan Abu Dawood (Hadith # 1468):</strong> Al-Bara Ibn e Azab (RadiAllahu Anhu) narrated the Holy Prophet (peace and blessings of Allah be upon Him) said: “Beautify the Quran with your voices.”</p>

                            </div>

                            <div className="middle border p-3">
                            <h4>Online Tajweed Course</h4>
                            <hr />
                            <p>Listening to the Quran with Tajweed and without Tajweed can show a big difference. You can learn Tajwid by taking Tajweed classes. In these classes, students learn how to pronounce and recite the Quran correctly.</p>
                            <p><AiOutlineArrowRight className='main-color' />What is Tajweed</p>
                            <p><AiOutlineArrowRight className='main-color'/> Rules of Noon Saakin and Tanween</p>
                            <p><AiOutlineArrowRight className='main-color'/> Qalqala</p>
                            <p><AiOutlineArrowRight className='main-color'/> Rules of Sakin Mim and sakin Nun</p>
                            <p><AiOutlineArrowRight className='main-color'/> Heavy and light letters</p>
                            <p><AiOutlineArrowRight className='main-color'/> Stopping at the end of words</p>
                            <p><AiOutlineArrowRight className='main-color'/> Rules of Mad</p>
                            <p><AiOutlineArrowRight className='main-color'/> and others</p>
                            <p>Reciting the Quran without proper tajweed is considered impermissible. Even the Archangel Gabriel recited it with tajweed when imparting it to the revered Prophet Muhammad (peace and blessings of Allah be upon Him). It is incumbent upon us to strive to recite the Quran in a manner that closely mirrors the way of the Holy Prophet (peace and blessings of Allah be upon Him).</p>
                            
                            
                            </div>
                        </div>
                  </div>
                  <div className="col-lg-4">
                  
                      <div className="card shadow mb-3 p-3">
                          <h5 className='fw-bold yellow-color bg-dark p-3'>Course Structure:</h5>
                          <div className="side-content p-3">
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Course Name:</span> Online Quran Tajweed Course</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Class type:</span> One to One</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Age:</span> 6 to 60+</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>For:</span> Beginner, Intermediate, Advanced</p>
                          </div>
                      </div>

                      <div className="card p-3 shadow mb-3">
                          <Sidebar />
                      </div>

                  </div>
              </div>
          </div>
    </div>
    </>
  )
}

export default Tajweed