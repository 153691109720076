import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import Sidebar from '../components/Sidebar';

const Recitation = () => {
  return (
    <>
    <div className="noorani">
    <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <div className="top mb-4 border">
                      <h3 className='yellow-color pb-3 fw-bold bg-dark p-3'>Best Quran Recitation Course</h3>
                            <div className="main-content p-3">
                            <div className="img-wrapper">
                            <img src="images/recite.jpg" alt="sample" className='img-fluid' />
                            </div>
                            <p className='my-4'>Are you facing challenges in Quranic reading? Do you aspire to achieve fluency in Quran recitation with Tajweed? This online Quran Reading course (Recitation program) is tailored for individuals like you. With the guidance of our proficient Arab Quran Teachers, you will progress from fundamental to advanced levels in Quranic reading while mastering the rules of Tajweed.</p>

                            </div>

                            <div className="middle border p-3">
                            <h4>More About the Online Learning Quran Recitation</h4>
                            <hr />
                            <p>Through online  <span className='main-color fw-bold'>WhatsApp</span>, <span className='main-color fw-bold'>Skype</span> sessions, you and your children have the opportunity to learn how to read the Quran. This course is designed to assist them in correctly reciting the Quran, focusing on the authentic recitation style. Our qualified Quran tutors will provide instruction on the essential regulations for accurately reciting the words and verses of the Quran.</p>
                            <p><AiOutlineArrowRight className='main-color' /> Recitation of short & long surahs.</p>
                            <p><AiOutlineArrowRight className='main-color'/> Different rules of tajweed like Ikhfa Meem Saakin, Qalb, Ghunna …</p>
                            <p>Upon completing this curriculum, both you and your children will acquire the skill to accurately recite the Noble Quran with proper Makharij, pronunciation, and adherence to all Tajweed rules. However, prior to enrolling in the Quran reading course with Tajweed rules, students must successfully complete the evaluation of the Noorani Qaida Course.</p>
                            


                           
                            </div>
                        </div>
                  </div>
                  <div className="col-lg-4">
                      <div className="card shadow mb-3 p-3">
                          <h5 className='fw-bold yellow-color bg-dark p-3'>Course Structure:</h5>
                          <div className="side-content p-3">
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Course Name:</span> Online Quran Recitation Course</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Class type:</span> One to One</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Age:</span> 6 to 60+</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>For:</span> Beginner, Intermediate, Advanced</p>
                          </div>
                      </div>

                      <div className="card p-3 shadow mb-3">
                          <Sidebar />
                      </div>

                  </div>
              </div>
          </div>
    </div>
    </>
  )
}

export default Recitation