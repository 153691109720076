import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

function Navbar() {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleNavbarToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const dropDownClick = ()=>
  {
    window.scrollTo(0, 0);
  }

  const closeNavbar = () => {
    setIsNavbarOpen(false);
    dropDownClick(0);
  };


  const handleNavLinkClick = () => {
    closeNavbar();
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg main-bg shadow-lg fixed-top">
        <div className="container">

          <Link className="navbar-brand" to="/">
            <img src="images/Logo.png"  className='img-fluid ' width={70} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={handleNavbarToggle}
            aria-expanded={isNavbarOpen}
          >
            <FaBars className='text-light' /> <span className='text-light'>Menu</span>
          </button>
          <div
            className={`collapse navbar-collapse${isNavbarOpen ? ' show' : ''}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" onClick={handleNavLinkClick} to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Course
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><Link className="dropdown-item" to="/noorani" onClick={closeNavbar}>Noorani Qaida course</Link></li>
                <li><Link className="dropdown-item" to="/recitation" onClick={closeNavbar}>Quran Recitation Course</Link></li>
                <li><Link className="dropdown-item" to="/memorization" onClick={closeNavbar}>Quran Memorization course</Link></li>
                <li><Link className="dropdown-item" to="/tajweed" onClick={closeNavbar}>Quran Tajweed course</Link></li>
                <li><Link className="dropdown-item" to="/kalma" onClick={closeNavbar}>Kamlas & Duas</Link></li>
                <li><Link className="dropdown-item" to="/naat" onClick={closeNavbar}>Naat Course</Link></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" onClick={handleNavLinkClick} to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" onClick={handleNavLinkClick} to="/fee">
                  Fee Structure
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" onClick={handleNavLinkClick} to="/download">
                  Downloads
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" onClick={handleNavLinkClick} to="/contact">
                  Contact
                </Link>
              </li>
              <li>
                <Link className="btn bg-warning rounded-pill free-trail" onClick={handleNavLinkClick} to="/contact">
                  Free Trail
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {isNavbarOpen && (
        <div className="overlay" onClick={closeNavbar} />
      )}
    </>
  );
}

export default Navbar;
