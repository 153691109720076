import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Fee = () => {
  return (
    <>
      <div className="fee">
      <div className="top second-bg py-5">
                    <h2 className='text-center fw-bold'>Monthly Fee Packages of<br /> <span className='main-color'>Online Quran Classes</span></h2>
                    <p className='text-center'>Choose a package that best fits to your right education</p>
                </div>
            <div className="container">
                
            <div className="row mt-5">
                <div className="col-lg-3">
                    <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                        <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>12 Classes / Month</h4>
                        <h4 className='text-center main-color h5 fw-bold'>30 Minutes Class</h4>

                        <h6 className='text-center fw-bold '>One to One Class</h6>
                        <hr />
                    <div className="feelist d-flex flex-column  align-items-center">
                        <h6 className='price'><AiOutlineArrowRight /> USD $40 only / Month</h6>
                        <h6><AiOutlineArrowRight /> CAD $54 only / Month</h6>
                        <h6><AiOutlineArrowRight /> AUD $62 only / Month</h6>
                        <h6><AiOutlineArrowRight /> GBP £32 only / Month</h6>
                        <h6><AiOutlineArrowRight /> Euro €37 only / Month</h6>
                    </div>

                    <hr />
                    </div>
                </div>
                
                <div className="col-lg-3">
                    <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                        <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>16 Classes / Month</h4>
                        <h4 className='text-center main-color h5 fw-bold'>30 Minutes Class</h4>
                        <h6 className='text-center fw-bold '>One to One Class</h6>
                        <hr />
                    <div className="feelist d-flex flex-column  align-items-center">
                        <h6 className='price'><AiOutlineArrowRight /> USD $45 only / Month</h6>
                        <h6><AiOutlineArrowRight /> CAD $60 only / Month</h6>
                        <h6><AiOutlineArrowRight /> AUD $70 only / Month</h6>
                        <h6><AiOutlineArrowRight /> GBP £36 only / Month</h6>
                        <h6><AiOutlineArrowRight /> Euro €42 only / Month</h6>
                    </div>

                    <hr />
                    </div>
                </div>
                
                <div className="col-lg-3">
                    <div className="card pb-3 mt-3 px-3 shadow mx-2 second-bg">
                        <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>20 Classes / Month</h4>
                        <h4 className='text-center main-color h5 fw-bold'>30 Minutes Class</h4>
                        <h6 className='text-center fw-bold '>One to One Class</h6>
                        <hr />
                    <div className="feelist d-flex flex-column  align-items-center">
                        <h6 className='price'><AiOutlineArrowRight /> USD $50 only / Month</h6>
                        <h6><AiOutlineArrowRight /> CAD $68 only / Month</h6>
                        <h6><AiOutlineArrowRight /> AUD $78 only / Month</h6>
                        <h6><AiOutlineArrowRight /> GBP £40 only / Month</h6>
                        <h6><AiOutlineArrowRight /> Euro €46 only / Month</h6>
                    </div>

                    <hr />
                    </div>
                </div>
                
                <div className="col-lg-3">
                    <div className="card pb-3 mt-3 px-3 shadow mx-2 second-bg">
                        <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>24 Classes / Month </h4>
                        <h4 className='text-center main-color h5 fw-bold'>30 Minutes Class</h4>
                        <h6 className='text-center fw-bold '>One to One Class</h6>
                        <hr />
                    <div className="feelist d-flex flex-column  align-items-center">
                        <h6 className='price'><AiOutlineArrowRight /> USD $60 only / Month</h6>
                        <h6><AiOutlineArrowRight /> CAD $81 only / Month</h6>
                        <h6><AiOutlineArrowRight /> AUD $93 only / Month</h6>
                        <h6><AiOutlineArrowRight /> GBP £47 only / Month</h6>
                        <h6><AiOutlineArrowRight /> Euro €55 only / Month</h6>
                    </div>

                    <hr />
                    </div>
                </div>
            </div>
<hr />
            <div className="row mt-5">
            <div className="col-lg-3">
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                    <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>12 Classes / Month</h4>
                    <h4 className='text-center main-color h5 fw-bold'>60 Minutes Class</h4>
                    <h6 className='text-center fw-bold '>One to One Class</h6>
                    <hr />
                <div className="feelist d-flex flex-column  align-items-center">
                    <h6 className='price'><AiOutlineArrowRight /> USD $75 only / Month</h6>
                    <h6><AiOutlineArrowRight /> CAD $102 only / Month</h6>
                    <h6><AiOutlineArrowRight /> AUD $115 only / Month</h6>
                    <h6><AiOutlineArrowRight /> GBP £59 only / Month</h6>
                    <h6><AiOutlineArrowRight /> Euro €69 only / Month</h6>
                </div>

                <hr />
                </div>
            </div>
            
            <div className="col-lg-3">
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                    <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>16 Classes / Month</h4>
                    <h4 className='text-center main-color h5 fw-bold'>60 Minutes Class</h4>
                    <h6 className='text-center fw-bold '>One to One Class</h6>
                    <hr />
                <div className="feelist d-flex flex-column  align-items-center">
                    <h6 className='price'><AiOutlineArrowRight /> USD $85 only / Month</h6>
                    <h6><AiOutlineArrowRight /> CAD $115 only / Month</h6>
                    <h6><AiOutlineArrowRight /> AUD $131 only / Month</h6>
                    <h6><AiOutlineArrowRight /> GBP £67 only / Month</h6>
                    <h6><AiOutlineArrowRight /> Euro €79 only / Month</h6>
                </div>

                <hr />
                </div>
            </div>
            
            <div className="col-lg-3">
                <div className="card pb-3 mt-3 px-3 shadow mx-2 second-bg">
                    <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>20 Classes / Month</h4>
                    <h4 className='text-center main-color h5 fw-bold'>60 Minutes Class</h4>
                    <h6 className='text-center fw-bold '>One to One Class</h6>
                    <hr />
                <div className="feelist d-flex flex-column  align-items-center">
                    <h6 className='price'><AiOutlineArrowRight /> USD $100 only / Month</h6>
                    <h6><AiOutlineArrowRight /> CAD $135 only / Month</h6>
                    <h6><AiOutlineArrowRight /> AUD $155 only / Month</h6>
                    <h6><AiOutlineArrowRight /> GBP £79 only / Month</h6>
                    <h6><AiOutlineArrowRight /> Euro €92 only / Month</h6>
                </div>

                <hr />
                </div>
            </div>
            
            <div className="col-lg-3">
                <div className="card  pb-3 mt-3 px-3 shadow mx-2 second-bg">
                    <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>24 Classes / Month </h4>
                    <h4 className='text-center main-color h5 fw-bold'>60 Minutes Class</h4>
                    <h6 className='text-center fw-bold '>One to One Class</h6>
                    <hr />
                <div className="feelist d-flex flex-column  align-items-center">
                    <h6 className='price'><AiOutlineArrowRight /> USD $110 only / Month</h6>
                    <h6><AiOutlineArrowRight /> CAD $150 only / Month</h6>
                    <h6><AiOutlineArrowRight /> AUD $170 only / Month</h6>
                    <h6><AiOutlineArrowRight /> GBP £87 only / Month</h6>
                    <h6><AiOutlineArrowRight /> Euro €121 only / Month</h6>
                </div>

                <hr />
                </div>
            </div>
        </div>
            <hr />

            <div className="row mt-5 d-flex justify-content-center">
            <div className="col-lg-3">
                <div className="card  mt-3 pb-3 px-3 shadow mx-2 second-bg">
                    <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>8 Classes / Month</h4>
                    <h4 className='text-center main-color h5 fw-bold'>30 Minutes Class</h4>
                    <h6 className='text-center fw-bold '>One to One Class</h6>
                    <h5 className='text-center fw-bold main-color'>Saturaday & Sunday</h5>

                    <hr />
                <div className="feelist d-flex flex-column  align-items-center">
                    <h6 className='price'><AiOutlineArrowRight /> USD $30 only / Month</h6>
                    <h6><AiOutlineArrowRight /> CAD $41 only / Month</h6>
                    <h6><AiOutlineArrowRight /> AUD $56 only / Month</h6>
                    <h6><AiOutlineArrowRight /> GBP £25 only / Month</h6>
                    <h6><AiOutlineArrowRight /> Euro €28 only / Month</h6>
                </div>

                <hr />
                </div>
            </div>
            
            <div className="col-lg-3">
                <div className="card  mt-3 pb-3 px-3 shadow mx-2 second-bg">
                    <h4 className='text-center text-uppercase fw-bold text-light bg-dark py-2 shadow feeCardHeading'>8 Classes / Month</h4>
                    <h4 className='text-center main-color h5 fw-bold'>60 Minutes Class</h4>
                    <h6 className='text-center fw-bold '>One to One Class</h6>
                    <h5 className='text-center fw-bold main-color'>Saturaday & Sunday</h5>


                    <hr />
                <div className="feelist d-flex flex-column  align-items-center">
                    <h6 className='price'><AiOutlineArrowRight /> USD $60 only / Month</h6>
                    <h6><AiOutlineArrowRight /> CAD $81 only / Month</h6>
                    <h6><AiOutlineArrowRight /> AUD $93 only / Month</h6>
                    <h6><AiOutlineArrowRight /> GBP £47 only / Month</h6>
                    <h6><AiOutlineArrowRight /> Euro €55 only / Month</h6>
                </div>

                <hr />
                </div>
            </div>
            
            
        </div>



            </div>
            
      </div>
    </>
  )
}

export default Fee