import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../components/Sidebar'

const About = () => {

  
  return (
    <>
      <div className="about">
                <div className="top-section text-center text-lg-start">
                  <div className="container">
                  <div className="row d-flex justify-content-end">
                  <div className="col-lg-6 mt-5">
                      <h2 className='mt-5 fw-bold text-light'>Nurjan Quran Academy</h2>
                        <div className="img-wrapper my-4 text-center text-lg-start">
                          <img src="images/whiteunderline-nurjan.png" alt="underline" className='img-fluid ' width={200} />
                        </div>
                      <p className='text-light mt-4'>Nurjan Quran Academy is a group of dedicated professionals. We invite everyone to join us and learn the holy Quran and the commandments of Islam. It doesn't matter where you're from. Our goal is to help everyone to understand and follow the important parts of our religion. This way, we can become a better community and make the world better for everyone. Our Quran lessons are easy to understand. We help you learn the Quran step by step. We pay the same attention to each student. It doesn't matter how fast you learn – we make sure everyone has a fair chance to learn the holy Quran.</p>
                  
                      <Link className='btn btn-warning mt-4' to="/contact">Contact us</Link>
                  </div>
        </div>  
                  </div>
                </div>



                

                <div className="bottom-section py-5 mb-5 text-center text-lg-start">
                    
                <div className="container ">
                    <div className="row d-flex justify-content-between ">
                        <div className="col-lg-8">
                            <p>At Nurjan Quran Academy, we empathize with the challenges that Muslims face in accessing traditional Quranic classes, particularly in countries where Islam isn't the predominant religion. The delicate balance between work, studies, and family responsibilities further complicates matters. To effectively tackle these issues, we have committed ourselves to developing comprehensive online Quranic courses. Our online courses have been meticulously designed to empower participants to engage with the Quran at their own convenience, accommodating the diverse and often demanding lifestyles of modern Muslims. Learning begins in the comfort of one's own home, with flexible timetables tailored to individual preferences. This approach allows learners to embark on a transformative journey to grasp the essence of the Holy Quran, regardless of their learning pace.</p>
                            <p>At the heart of Nurjan Quran Academy's mission is the dedication to imparting the art of tajweed and unraveling the Quran's profound meanings, one word at a time, until mastery is achieved. Our commitment extends beyond teaching the Quran alone. We aspire to cultivate good morals and guide our students on living in accordance with Islamic values.
                            The backbone of our academy is our team of experienced Quran teachers, who offer personalized one-on-one online lessons. Fluent in English and well-versed in the intricacies of the Quran, our teachers tailor their instruction to each student's pace and understanding. This personalized approach ensures that students can truly comprehend the teachings of the holy Quran.</p>

                            <p>In the contemporary era, e-learning has evolved into an essential educational platform that is gaining global recognition. The success of online education hinges on the instructor's ability to captivate and sustain their students' attention. At Nurjan Quran Academy, we are committed to excellence in online instruction. We meticulously recruit skilled and certified personnel who excel in their respective fields and possess prior experience in online teaching.
                              In a world where time is precious and schedules are demanding, Nurjan Quran Academy stands as a beacon of flexible, comprehensive, and personalized online Quranic education. We are here to make the teachings of the Quran accessible to all, fostering a deeper connection to faith and knowledge, all while accommodating the realities of contemporary life.
                            </p>
                        </div>

                        <div className="col-lg-4 ">

                              <Sidebar />
                        </div>
                    </div>
                    </div>
                
                </div>

                
                <div className="middle-section pb-5">
                  <div className="container py-5">
                    <h2 className='text-center fw-bold mb-5'>What makes us <span className='main-color'>different</span> from others?</h2>

                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-4 mt-3">
                              <div className="card card-border p-4 shadow">
                                  <h4 className='text-center fw-bold main-color lh-sm'>Monitoring system & schedule</h4>
                                  <p className='mt-3 pb-4'>Our team of experts is available 24/7 to help you and your children learn the Quran online at your convenience. No matter where you are, you can easily join our classes and enjoy this wonderful opportunity for learning, day or night.</p>
                              </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                              <div className="card card-border p-4 shadow">
                                  <h4 className='text-center fw-bold main-color lh-sm'>Student monthly test report</h4>
                                  <p className='mt-3 pb-4'>Monitor your child's progress with ease through our online Quran academy. Receive daily, weekly and monthly performance reports, ensuring a seamless and effective online Quran learning experience for your kids.</p>
                              </div>
                        </div>
                        
                    </div>
                  </div>
                </div>
      </div>
      
    </>
  )
}

export default About