import React, { useState } from 'react'
import emailjs from 'emailjs-com';


const Form = () => {

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [daysperweek, setDaysperweek] = useState('');
  const [country, setCountry] = useState('');
  const [message, setMessage] = useState('');


  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace these values with your own
    const serviceId = 'service_gqwp0jl';
  const templateId = 'template_0ipu0nh';
  const userId = '5JbaVghxOhxqeUAxH';

    const templateParams = {
      from_name: fullName,
      reply_to: email,
      phone: `Phone: ${phone}`,
      message: message,
      sender: `Sender: ${email}`, // Modify the subject here
      daysperweek: `Days per week: ${daysperweek}`,
      country: `Country: ${country}`
    };

    setFullName('');
    setEmail('');
    setPhone('');
    setDaysperweek('');
    setCountry('');
    setMessage('');

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then(() => {
        alert('Thank you for contacting....!');
      })
      .catch((error) => {
        alert('Sorry, Message is not sending successfully', error);
      });
  };


  return (
    <>
        <form onSubmit={handleSubmit}>
                    <input type="text" className='form-control' placeholder='full name'value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            required/>
                            
                    <input type="email" className='form-control mt-2' placeholder='email' value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required/>


                    <input type="Phone" className='form-control mt-2' placeholder='phone number' value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required/>
                    <input type="text" className='form-control mt-2' placeholder='enter days per week' value={daysperweek}
                           onChange={(e)=> setDaysperweek(e.target.value)}
                           required
                    />
                    <input type="text" className='form-control mt-2' placeholder='country' value={country}
                           onChange={(e)=> setCountry(e.target.value)}
                           required/>
                          

                    <textarea rows="3" className='form-control mt-2' placeholder='Additional details: for example, days of the week that you want to choose to study...'
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required></textarea>
                    <div className="text-center d-grid">
                    <button type='submit' className='btn yellow-bg  text-uppercase fw-bold mt-4'>Book Free Trail Now</button>
                    </div>
            
        </form>
    </>
  )
}

export default Form