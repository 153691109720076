import './App.css';
import Routing from './components/Routing';
import WhatsAppButton from './components/WhatsAppButton';

function App() {
  return (
    <>
      <Routing />
      <WhatsAppButton />
    </>
  );
}

export default App;
