import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import Sidebar from '../components/Sidebar';

const Memorization = () => {
  return (
    <>
    
    <div className="noorani">
    <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <div className="top mb-4 border">
                      <h3 className='yellow-color pb-3 fw-bold bg-dark p-3'>Best Online Quran Memorization Course</h3>
                            <div className="main-content p-3">
                            <div className="img-wrapper">
                            <img src="images/memorize.jpg" alt="sample" className='img-fluid' />
                            </div>
                            <p className='my-4'>The online Quran memorization course, known as the Quran Hifz program, is well-suited for individuals of diverse ages (both children and adults), educational backgrounds, and varying schedules. This program offers private, one-on-one online sessions conducted by experienced male and female Arab Quran Teachers.</p>

                            </div>

                            <div className="middle border p-3">
                            <h4>Online Quran Memorization Course</h4>
                            <hr />
                            <p>Learning can take place online through platforms like <strong className='main-color'>WhatApp</strong> , <strong className='main-color'>Skype</strong> . Enrolling in a <strong>Noorani Qaida course</strong> can be beneficial for mastering the pronunciation of Arabic alphabets. As learners progress, they can gradually advance to forming words by combining these letters.</p>
                            <p><AiOutlineArrowRight className='main-color' /> Memorizing the short Surahs (Annas, Al-Falaq, Al-Ikhlas, …).</p>
                            <p><AiOutlineArrowRight className='main-color'/> Memorizing the last Juz’ (No. 30, Juz’ Amm).</p>
                            <p><AiOutlineArrowRight className='main-color'/> Memorizing some selected Surahs chosen (Al-Mulk, Yaseen, …).</p>
                            <p>We require the Quran memorization course to be exclusively available to students who can already recite the Quran accurately with Tajweed. If your recitation proficiency is not at the desired level, you are welcome to enroll in the quran recitaion course first.</p>
                            


                            <h4 className='mt-5'>Read More About the <strong className='main-color text-decoration-underline'>Quran Memorazation</strong></h4>
                            <hr />

                            <p> <strong>What is Quran Memorization?</strong></p>
                            <p>The process of committing the Quran to memory is known as the Quran Memorization Course. In Arabic, an individual who has successfully memorized the Quran is referred to as a "Hafiz." The term "Hifz" denotes the act of memorization, making "Hafiz e Quran" signify the accomplishment of both reading and memorizing its verses and Ayat.</p>
                            <p>The Quran stands as a unique blessing from Allah Almighty, irreplaceable by anything within the universe, encompassing the heavens, earth, and all beings found in between.</p>
                            <p>The Qur'an stands as an unparalleled ocean of knowledge, unrivaled by any worldly book. All other books draw upon the wisdom distilled from the Qur'an, yet they focus on specific subjects. In contrast, the Qur'an encompasses every conceivable aspect within its scope.</p>
                            <p>The Quran comprehensively addresses every worldly issue, underscoring the significance of committing it to memory and reciting it. This practice ensures direct access to its wisdom whenever challenges arise.</p>
                           
                            </div>
                        </div>
                  </div>
                  <div className="col-lg-4">
                  
                      <div className="card shadow mb-3 p-3">
                          <h5 className='fw-bold yellow-color bg-dark p-3'>Course Structure:</h5>
                          <div className="side-content p-3">
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Course Name:</span> Online Quran Memorization Course</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Class type:</span> One to One</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Age:</span> 6 to 60+</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>For:</span> Beginner, Intermediate, Advanced</p>
                          </div>
                      </div>

                      <div className="card p-3 shadow mb-3">
                          <Sidebar />
                      </div>

                  </div>
              </div>
          </div>
    </div>
    
    </>
  )
}

export default Memorization