import React from 'react'

const Download = () => {
  return (
    <>
        <div className="noorani">
            <div className="container">
                <h1 className='text-center main-color'>Downloads</h1>
                <hr />

                <div className="row">
                    <div className="col-lg-2">
                        <a href="https://drive.google.com/file/d/15onhKQ87p-cVaq-GR-3aqiszxax4HD0o/view?usp=sharing" target='_blank' className='text-decoration-none'>
                        <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                        <div className="img-wrapper mt-3">
                        <img src="images/noorani.jpg" alt="noorani qaida" className='img-fluid'/>
                        <hr />

                        <h3 className='text-center text-dark h5'>Noorani Qaida</h3>
                        </div>
                  </div>
                        </a>
                    </div>
                    
                </div>

                <hr />

                <div className="row">

                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-1-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 1</h3>
                  </div>
            </div>
                  </a>
                  </div>
                  
                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-2-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 2</h3>
                  </div>
            </div>
                  </a>
                  </div>

                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-3-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 3</h3>
                  </div>
            </div>
                  </a>
                  </div>


                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-4-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 4</h3>
                  </div>
            </div>
                  </a>
                  </div>


                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-5-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 5</h3>
                  </div>
            </div>
                  </a>
                  </div>


                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-6-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 6</h3>
                  </div>
            </div>
                  </a>
                  </div>
                 
                  
                </div>


                <div className="row">

                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-7-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 7</h3>
                  </div>
            </div>
                  </a>
                  </div>
                  
                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-8-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 8</h3>
                  </div>
            </div>
                  </a>
                  </div>

                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-9-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 9</h3>
                  </div>
            </div>
                  </a>
                  </div>


                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-10-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 10</h3>
                  </div>
            </div>
                  </a>
                  </div>


                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-11-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 11</h3>
                  </div>
            </div>
                  </a>
                  </div>


                  <div className="col-lg-2">
                  <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-12-pdf.pdf" target='_blank' className='text-decoration-none'>
                  <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                  <div className="img-wrapper mt-3">
                  <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                  <hr />

                  <h3 className='text-center text-dark h5'>Para 12</h3>
                  </div>
            </div>
                  </a>
                  </div>
                 
                  
                </div>


                <div className="row">

                <div className="col-lg-2">
                <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-13-pdf.pdf" target='_blank' className='text-decoration-none'>
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                <div className="img-wrapper mt-3">
                <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                <hr />

                <h3 className='text-center text-dark h5'>Para 13</h3>
                </div>
          </div>
                </a>
                </div>
                
                <div className="col-lg-2">
                <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-14-pdf.pdf" target='_blank' className='text-decoration-none'>
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                <div className="img-wrapper mt-3">
                <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                <hr />

                <h3 className='text-center text-dark h5'>Para 14</h3>
                </div>
          </div>
                </a>
                </div>

                <div className="col-lg-2">
                <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-15-pdf.pdf" target='_blank' className='text-decoration-none'>
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                <div className="img-wrapper mt-3">
                <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                <hr />

                <h3 className='text-center text-dark h5'>Para 15</h3>
                </div>
          </div>
                </a>
                </div>


                <div className="col-lg-2">
                <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-16-pdf.pdf" target='_blank' className='text-decoration-none'>
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                <div className="img-wrapper mt-3">
                <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                <hr />

                <h3 className='text-center text-dark h5'>Para 16</h3>
                </div>
          </div>
                </a>
                </div>


                <div className="col-lg-2">
                <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-17-pdf.pdf" target='_blank' className='text-decoration-none'>
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                <div className="img-wrapper mt-3">
                <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                <hr />

                <h3 className='text-center text-dark h5'>Para 17</h3>
                </div>
          </div>
                </a>
                </div>


                <div className="col-lg-2">
                <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-18-pdf.pdf" target='_blank' className='text-decoration-none'>
                <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
                <div className="img-wrapper mt-3">
                <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
                <hr />

                <h3 className='text-center text-dark h5'>Para 18</h3>
                </div>
          </div>
                </a>
                </div>
               
                
              </div>


              <div className="row">

              <div className="col-lg-2">
              <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-19-pdf.pdf" target='_blank' className='text-decoration-none'>
              <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
              <div className="img-wrapper mt-3">
              <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
              <hr />

              <h3 className='text-center text-dark h5'>Para 19</h3>
              </div>
        </div>
              </a>
              </div>
              
              <div className="col-lg-2">
              <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-20-pdf.pdf" target='_blank' className='text-decoration-none'>
              <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
              <div className="img-wrapper mt-3">
              <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
              <hr />

              <h3 className='text-center text-dark h5'>Para 20</h3>
              </div>
        </div>
              </a>
              </div>

              <div className="col-lg-2">
              <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-21-pdf.pdf" target='_blank' className='text-decoration-none'>
              <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
              <div className="img-wrapper mt-3">
              <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
              <hr />

              <h3 className='text-center text-dark h5'>Para 21</h3>
              </div>
        </div>
              </a>
              </div>


              <div className="col-lg-2">
              <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-22-pdf.pdf" target='_blank' className='text-decoration-none'>
              <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
              <div className="img-wrapper mt-3">
              <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
              <hr />

              <h3 className='text-center text-dark h5'>Para 22</h3>
              </div>
        </div>
              </a>
              </div>


              <div className="col-lg-2">
              <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-23-pdf.pdf" target='_blank' className='text-decoration-none'>
              <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
              <div className="img-wrapper mt-3">
              <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
              <hr />

              <h3 className='text-center text-dark h5'>Para 23</h3>
              </div>
        </div>
              </a>
              </div>


              <div className="col-lg-2">
              <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-24-pdf.pdf" target='_blank' className='text-decoration-none'>
              <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
              <div className="img-wrapper mt-3">
              <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
              <hr />

              <h3 className='text-center text-dark h5'>Para 24</h3>
              </div>
        </div>
              </a>
              </div>
             
              
            </div>


            <div className="row">

            <div className="col-lg-2">
            <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-25-pdf.pdf" target='_blank' className='text-decoration-none'>
            <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
            <div className="img-wrapper mt-3">
            <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
            <hr />

            <h3 className='text-center text-dark h5'>Para 25</h3>
            </div>
      </div>
            </a>
            </div>
            
            <div className="col-lg-2">
            <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-26-pdf.pdf" target='_blank' className='text-decoration-none'>
            <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
            <div className="img-wrapper mt-3">
            <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
            <hr />

            <h3 className='text-center text-dark h5'>Para 26</h3>
            </div>
      </div>
            </a>
            </div>

            <div className="col-lg-2">
            <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-27-pdf.pdf" target='_blank' className='text-decoration-none'>
            <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
            <div className="img-wrapper mt-3">
            <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
            <hr />

            <h3 className='text-center text-dark h5'>Para 27</h3>
            </div>
      </div>
            </a>
            </div>


            <div className="col-lg-2">
            <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-28-pdf.pdf" target='_blank' className='text-decoration-none'>
            <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
            <div className="img-wrapper mt-3">
            <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
            <hr />

            <h3 className='text-center text-dark h5'>Para 28</h3>
            </div>
      </div>
            </a>
            </div>


            <div className="col-lg-2">
            <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-29-pdf.pdf" target='_blank' className='text-decoration-none'>
            <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
            <div className="img-wrapper mt-3">
            <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
            <hr />

            <h3 className='text-center text-dark h5'>Para 29</h3>
            </div>
      </div>
            </a>
            </div>


            <div className="col-lg-2">
            <a href="https://islamic1articleshome.files.wordpress.com/2020/05/13-line-tajwidi-quran-sipara-30-pdf.pdf" target='_blank' className='text-decoration-none'>
            <div className="card mt-3 pb-3 px-3 shadow mx-2 second-bg">
            <div className="img-wrapper mt-3">
            <img src="images/recite.jpg" alt="noorani qaida" className='img-fluid'/>
            <hr />

            <h3 className='text-center text-dark h5'>Para 30</h3>
            </div>
      </div>
            </a>
            </div>
           
            
          </div>

            </div>
        </div>
    </>
  )
}

export default Download