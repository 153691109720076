import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import Sidebar from '../components/Sidebar';

const Kalma = () => {
  return (
    <>
    <div className="noorani">
    <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <div className="top mb-4 border">
                      <h3 className='yellow-color pb-3 fw-bold bg-dark p-3'>Best Online Kalma and Dua Course</h3>
                            <div className="main-content p-3">
                            <div className="img-wrapper">
                            <img src="images/kalma.jpg" alt="sample" className='img-fluid' />
                            </div>
                            <p className='my-4'>The Six Kalimah in South Asian Islam are six important parts of a person's religious beliefs. These beliefs are mostly based on teachings from hadiths, which are traditional sayings and actions of the Prophet Muhammad. Every Muslim is expected to learn these Six Kalimas. The first one is called Kalma Taiybah, the second is Kalma Shahadat, the third is Kalma e Tamjeed, the fourth is Kalma Tauheed, the fifth is Kalma e Astaghfar, and the last one is Kalma e Radd e Kuffr.</p>
                            </div>

                            <div className="middle border p-3">
                            <h4>Namaz and Duas</h4>
                            <hr />
                            <p><strong>Namaz</strong> is a way for Muslims to talk to Allah and show gratitude for blessings. It's essential in their lives, done five times daily with deep respect. Learning how to perform Namaz is crucial. Muslims consider it a direct conversation with Allah. We also teach Kalma with highly qualified Islamic teachers, covering all you need to know. We aim to provide the best guidance. "Salah," often misunderstood as "prayer," is better described as "divine communication" or "connecting with the divine" rather than a request to God.</p>
                           <p>In this course, we aim to educate our students about the essential Masnoon Duain, which are prayers and supplications derived from the teachings of the Holy Hadith. These Duas are based on specific instructions and guidance provided in the Holy Quran and Hadith for various daily activities. Our highly qualified Islamic instructors are dedicated to imparting comprehensive knowledge. At Nurjan Quran Academy, our experienced teachers focus on teaching students the fundamental daily Duas that every Muslim should be familiar with. These Masnoon
                            Duas play a vital role in our lives, such as before sleeping, entering the mosque, sneezing, leaving the mosque, fasting, and many more aspects of daily life. Learning these supplications is crucial for every Muslim as they address a wide range of situations and needs.</p>
                            </div>
                        </div>
                  </div>
                  <div className="col-lg-4">
                  
                      <div className="card shadow mb-3 p-3">
                          <h5 className='fw-bold yellow-color bg-dark p-3'>Course Structure:</h5>
                          <div className="side-content p-3">
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Course Name:</span> Kalmas and Duas Course</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Class type:</span> One to One</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Age:</span> 6 to 60+</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>For:</span> Beginner, Intermediate, Advanced</p>
                          </div>
                      </div>

                      <div className="card p-3 shadow mb-3">
                          <Sidebar />
                      </div>

                  </div>
              </div>
          </div>
    </div>
    </>
  )
}

export default Kalma