import React from 'react'
import { AiOutlineArrowRight } from 'react-icons/ai';
import Sidebar from '../components/Sidebar';

const Naat = () => {
  return (
    <>
    <div className="noorani">
    <div className="container">
              <div className="row">
                  <div className="col-lg-8">
                      <div className="top mb-4 border">
                      <h3 className='yellow-color pb-3 fw-bold bg-dark p-3'>Online Naat Learning Course</h3>
                            <div className="main-content p-3">
                            <div className="img-wrapper">
                            <img src="images/Naat.jpg" alt="sample" className='img-fluid' />
                            </div>
                            </div>
                            <hr />
                            <h1 className='text-center my-5 main-color'>Coming Soon...!</h1>
                        </div>
                  </div>
                  <div className="col-lg-4">
                  
                      <div className="card shadow mb-3 p-3">
                          <h5 className='fw-bold yellow-color bg-dark p-3'>Course Structure:</h5>
                          <div className="side-content p-3">
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Course Name:</span> Kalmas and Duas Course</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Class type:</span> One to One</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>Age:</span> 6 to 60+</p>
                                <p><AiOutlineArrowRight /> <span className='fw-bold'>For:</span> Beginner, Intermediate, Advanced</p>
                          </div>
                      </div>

                      <div className="card p-3 shadow mb-3">
                          <Sidebar />
                      </div>

                  </div>
              </div>
          </div>
    </div>
    </>
  )
}

export default Naat