import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  const openWhatsAppChat = () => {
    // Replace this with the actual WhatsApp chat link
    window.open('https://wa.me/923406239634', '_blank');
  };

  return (
    <>
    <button className="whatsapp-button" onClick={openWhatsAppChat}>
      <FaWhatsapp size={30} />
    </button>
    
    </>
      );
};

export default WhatsAppButton;
