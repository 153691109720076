import React from 'react'
import Form from '../components/Form'
import { FaWhatsapp, FaPhone, FaEnvelope} from 'react-icons/fa';





const Contact = () => {

  const whatsappHandler =()=> 
  {
      window.open('https://wa.me/923406239634', '_blank');
  }
  return (
    <>
    <div className="contact">
    <div className="top-contact text-center text-lg-start">
    <div className="container">
    <div className="row d-flex justify-content-start">
    <div className="col-lg-6 mt-5">
        <h2 className='mt-4 fw-bold text-dark'>Contact us</h2>
          <div className="img-wrapper my-4 text-center text-lg-start">
            <img src="images/whiteunderline-nurjan.png" alt="underline" className='img-fluid ' width={200} />
          </div>
          <h5 className='mt-5 fw-bold'><FaPhone className='me-2' size={20} /> : +92 340 6239 634</h5>
          <h5 className='mt-5 fw-bold'><FaWhatsapp className='me-2' size={20} /> : +92 340 6239 634</h5>
          <h5 className='mt-3 fw-bold'><FaEnvelope className='me-2' size={20} /> : nurjanquranacademy@gmail.com</h5>
    </div>
</div>  
    </div>
  </div>



            <div className="container  pb-lg-5">
            <div className="row d-flex justify-content-around pt-3">
                
                <div className="col-lg-6 mt-3">
                    <div className="card mx-3 reach-us shadow p-4 p-lg-5 ">
                    <h3 className='my-4 fw-bold text-warning  text-center'>More Quick Ways to Reach US</h3>
                       <hr  />

                       <div className="img-wrapper text-center">
                          <img src="images/aboutquran.png" alt="contact-icon" className='img-fluid' width={200} />
                       </div>
                      <h3 className=' main-color text-center fw-bold '>Nurjan Quran Academy</h3>
                      <div className="d-grid mt-4">
                         <button className='bg-success btn fw-bold mt- shadow rounded-0 text-center text-uppercase text-light' onClick={whatsappHandler}><FaWhatsapp size={20} /> WhatsApp </button>
                       </div>
                      <div className="d-grid mt-4 mb-lg-4">
                         <button className='bg-warning btn fw-bold mt- shadow rounded-0 text-center text-uppercase text-light' > <FaPhone size={18} /> +92 337 4696 146 </button>
                       </div>

                    </div>
                </div>
                <div className="col-lg-6 mt-3 ">
                    <div className="card mx-3 quick-admission shadow p-4 p-lg-5">
                        <h3 className='my-4 fw-bold main-color  text-center'>Contact us for admission</h3>
                        <hr  className='mb-5'/>
                        <Form/>
                        <div className="pb-2"></div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-2">

                </div>
            </div>
            </div>
    </div>

    </>

  )
}

export default Contact