import React from 'react'
import Form from '../components/Form'


const Sidebar = () => {
    const whatsappHandler =()=> 
  {
      window.open('https://wa.me/923374696146', '_blank');
  }
  return (
    <>

    <h3 className='bg-warning p-3 text-center border-0 mb-3'>Enroll Now</h3>
    <div className="card shadow border p-4 " style={{backgroundColor:"#e6f0ee"}}>

    <h3 className='text-center pb-4'>Get 3 days free trail classes</h3>
  
        <Form />
    </div>
    <div className="d-grid mt-3">
    <button className='bg-success btn fw-bold mt- shadow rounded-0 text-center text-uppercase text-light p-3' onClick={whatsappHandler}>Or WhatsApp</button>
    </div>

    </>
  )
}

export default Sidebar